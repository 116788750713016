<template>
  <div class="list">
    <!-- 搜索栏 -->
    <div class="header-search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="身份证:">
          <el-input v-model="formInline.cardid" placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="formInline.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="学员类型:">
          <el-select v-model="formInline.type" placeholder="请选择学习类型">
            <el-option label="全部" value="0"></el-option>
            <el-option label="C1/C2/C5" value="1"></el-option>
            <el-option label="A2/B2从业资格" value="2"></el-option>
            <el-option label="A1/A2/A3/B2" value="4"></el-option>
            <el-option label="线下网签" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item style="margin-left:100px" label="选择月份导出数据:">

          <el-date-picker v-model="selectedMonth" type="month" placeholder="选择月">
          </el-date-picker>

          <ExportData :fields="json_fields" :filename="'导出.xls'" :buttonTitle="'导出'" :fetch="exportSelectedMonth">
          </ExportData>
        </el-form-item>

      </el-form>

    </div>

    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="tableData" border style="width: 100%" highlight-current-row>

        <el-table-column prop="1" label="姓名" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="selectStudent(scope.row)" size="small">{{ scope.row.studentname
              }}</el-button>
          </template>
        </el-table-column>

        <!-- <el-table-column @click="selectStudent"
                         prop="studentname"
                         label="姓名"
                         min-width="50"
                         align="center">
        </el-table-column> -->

        <el-table-column prop="cardid" label="身份证号码" min-width="180" align="center">
        </el-table-column>
        <el-table-column prop="models" label="驾照类型" min-width="50" align="center">
        </el-table-column>
        <el-table-column prop="subj1" label="科一学时" min-width="50" align="center">
        </el-table-column>
        <el-table-column prop="subj4" label="科四学时" min-width="50" align="center">
        </el-table-column>
        <el-table-column prop="openclassesdate" label="开训时间" min-width="150" align="center">
        </el-table-column>
        <el-table-column prop="sckxrq" label="实操开训时间" min-width="150" align="center">
        </el-table-column>

        <el-table-column prop="schoolname" label="驾校名称" min-width="60" align="center">
        </el-table-column>

        <el-table-column prop="phone" label="手机号" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="6" label="合同" min-width="80" align="center">

          <template slot-scope="scope">
            <el-button @click="showImg(1, scope.$index, scope.row)" size="small">查看</el-button>
          </template>

        </el-table-column>

        <el-table-column prop="1" label="认证照片" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="showImg(2, scope.$index, scope.row)" size="small">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间" min-width="50" align="center">
        </el-table-column>
        <el-table-column v-if="isShowDelete" label="操作" width="" align="center">
          <template slot-scope="scope">
            <el-button @click="handleDeleteOffline(scope.row)" type="danger" size="small">删除学员</el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <Pagination :total="total" @currentChange="handleCurrentChange"></Pagination>

    <!-- 照片弹窗 -->
    <div>
      <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <span v-if="img.length < 1">{{ dialogType == 1 ? '未签合同' : '暂无图片' }}</span>
        <img class="dia-img" :src="img">

        <span slot="footer" class="dialog-footer">

          <el-button v-if="img.length > 0 && dialogType == 1" type="danger" @click="removeContract">删 除</el-button>
          <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-if="isAdmin">
      <el-dialog :visible.sync="stuDialogVisible" width="90%" top="50" :before-close="handleClose">
        <SysStudentDetail ref="sysStudentDetail" :cardid="selectedCardId" :type="formInline.type"></SysStudentDetail>

        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
    </div>

  </div>

</template>

<script>
import {
  contract,
  verifyImg,
  deleteContract,
  studentList,
  deleteOfflineStudent,
  exportCurrentMonth,
  exportLastMonth,
  exportByRange

} from '@/api/student'
import Pagination from '@/components/pagination'
import ExportData from '@/components/exportData'
import SysStudentDetail from '@/components/system/sysStudentDetail.vue'
import { getFirstDay, getLastDay } from '@/utils/dateUtil'
export default {
  components: {
    Pagination,
    ExportData,
    SysStudentDetail
  },
  computed: {
    subj1Style (row) {
      return { 'color': 'red' }
    },
    subj4Style (row) {
      return { 'color': 'red' }
    },
  },
  data () {
    return {
      formInline: {
        cardid: '',
        type: '1',
        name: ''
      },
      selectedMonth: '',
      tableData: [],
      total: 0,
      dialogVisible: false,
      img: '',
      dialogType: 1,
      currentContractId: 0,
      isShowDelete: false,
      currentPage: 1,
      json_fields: {
        "用户id": "uid",
        "驾校": "schoolname",
        "姓名": "studentname",
        "身份证": "cardid",
        "手机": "phone",
        "驾照": "models",
        "学员编号": "studentno",
        "开班日期": "openclassesdate",
        "实操开训日期": "sckx",
        "创建日期": "createTime",
      },
      stuDialogVisible: false,
      selectedCardId: '',
      isAdmin: false
    }
  },
  methods: {
    onSubmit () {
      this.loadData(1, this.formInline.cardid, this.formInline.type, this.formInline.name)
    },
    handleClick () {

    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loadData(val, "", this.formInline.type, this.formInline.name)
    },
    async loadData (page, cardid, type, name) {
      const res = await studentList({ page, cardid, type, name })
      let list = res.data
      list.forEach(element => {
        console.log(element)
        // if (element.models == "A2" || element.models == "B2") {
        //   element.k1 = (element.k1 * (60 / 45)).toFixed(2)
        //   element.k4 = (element.k4 * (60 / 45)).toFixed(2)
        // } else {
        var k1Total = 9
        var k4Total = 7.5
        if (element.models == "A1") {
          k1Total = 10
          k4Total = 16
        }
        if (element.models == "A2") {
          k1Total = 8
          k4Total = 16
        }
        if (element.models == "A3") {
          k1Total = 14
          k4Total = 16
        }
        if (element.models == "B2") {
          k1Total = 10
          k4Total = 16
        }
        element.subj1 = (element.subjectonehours > k1Total ? k1Total : element.subjectonehours) + '/' + k1Total
        element.subj4 = (element.subjectfourhours > k4Total ? k4Total : element.subjectfourhours) + '/' + k4Total
        // }

      });
      this.tableData = list
      this.total = res.count
      this.isShowDelete = type == 3
    },
    async showImg (type, index, data) {
      this.currentContractId = ''
      this.img = ''
      let img = ''
      // 合同
      if (type == 1) {
        const res = await contract({ "uid": data.uid })
        if (res.data) {
          img = res.data.photo
          this.currentContractId = res.data.id
        }
      }
      // 认证照片
      if (type == 2) {
        const res = await verifyImg({ "uid": data.uid })
        if (res.data) {
          img = res.data.img
        }
      }
      if (type == 3) {

      }
      this.img = img
      this.dialogType = type
      this.dialogVisible = true
    },
    removeContract () {
      const that = this
      this.$confirm('确认删除？')
        .then(async _ => {
          const res = await deleteContract({ 'id': that.currentContractId })
          if (res.data) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
          } else {
            this.$message.error('删除失败');
          }
          that.dialogVisible = false
        })
        .catch(_ => { });
    },
    handleClose (done) {
      done()
    },
    async handleDeleteOffline (item) {
      this.$confirm('确认删除？')
        .then(async _ => {
          const res = await deleteOfflineStudent({ id: item.uid })
          if (res.data) {
            this.loadData(this.currentPage, this.formInline.cardid, this.formInline.type, this.formInline.name)
            this.$message({
              message: '删除成功',
              type: 'success'
            });
          } else {
            this.$message.error('删除失败');
          }

        })
        .catch(_ => { });

    },
    async currentMonth () {
      let res = await exportCurrentMonth()
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },

    async lastMonth () {
      let res = await exportLastMonth()
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },

    async exportSelectedMonth () {
      if (this.selectedMonth.length < 1) {
        return [{}]
      }
      let res = await exportByRange({ startTime: getFirstDay(this.selectedMonth), endTime: getLastDay(this.selectedMonth) })
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },
    selectStudent (item) {
      if (this.$store.state.login.userinfo.username == 'admin') {
        this.isAdmin = true
        this.selectedCardId = item.cardid
        this.stuDialogVisible = true
        // // this.$refs.sysStudentDetail.loadData(item.cardid)
        // console.log(this.$refs.sysStudentDetail)
      }
    }
  },
  async created () {
    this.loadData(1, '', 1, '')
  },


}

</script>
<style lang="less" scoped>
.list {
  padding: 10px;
}

.dia-img {
  width: 100%;
}

.el-dialog__body {
  text-align: center;
}

.el-button--text {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
</style>
