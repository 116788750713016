<template>
  <div>
    <el-menu :default-active="$route.path"
             router
             class="el-menu-vertical-demo"
             background-color="#112f50"
             text-color="#fff"
             active-text-color="#ffd04b"
             :collapse="isCollapse">
      <el-menu-item>
        <span slot="title">纵横二加一管理系统</span>
      </el-menu-item>
      <el-menu-item index="/">
        <i class="el-icon-menu"></i>
        <span slot="title">系统首页</span>
      </el-menu-item>

      <el-submenu index="/school">
        <template slot="title">
          <i class="el-icon-user-solid"></i>
          <span slot="title">驾校信息管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/school/studentList">学员信息</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/school/coachList">教练信息</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/school/schoolList">驾校信息</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/school/schoolNumsAddRecord">名额充值记录</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu v-if="userinfo.username == 'admin'"
                  index="/systemManage">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span slot="title">系统管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/systemManage/schoolManageList">驾校管理</el-menu-item>
          <el-menu-item index="/systemManage/schoolNumsRecord">驾校名额充值记录</el-menu-item>
          <el-menu-item index="/systemManage/dataExport">数据导出</el-menu-item>
          <el-menu-item index="/systemManage/cyManage">从业</el-menu-item>
          <el-menu-item index="/systemManage/serviceRecord">客服记录</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  props: ['isCollapse'],
  computed: {
    ...mapState('login', ['userinfo']),
  }
};
</script>

<style scpoed>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu .is-active {
  background: #1e76bf !important;
  color: #fff !important;
}
</style>
