import { post } from "./base"

export async function studentList (params) {
  try {
    const res = await post('bestudent/list', params)
    return res
  } catch (error) {
    throw error
  }
}


export async function contract (params) {
  try {
    const res = await post('bestudent/contract', params)
    return res
  } catch (error) {
    throw error
  }
}


export async function deleteContract (params) {
  try {
    const res = await post('bestudent/deleteContract', params)
    return res
  } catch (error) {
    throw error
  }
}


export async function verifyImg (params) {
  try {
    const res = await post('bestudent/verifyImg', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function deleteOfflineStudent (params) {
  try {
    const res = await post('bestudent/deleteOfflineStudent', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function exportCurrentMonth () {
  try {
    const res = await post('bestudent/exportCurrentMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportLastMonth () {
  try {
    const res = await post('bestudent/exportLastMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportByRange (params) {
  try {
    const res = await post('bestudent/exportByRange', params)
    return res
  } catch (error) {
    throw error
  }
}





