<template>
  <div class="detail_container">
    <div v-if="isShowSearch">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">
        <el-form-item label="身份证:">
          <el-input v-model="formInline.cardid"
                    placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="title">赛博信息:</div>
    <CyberInfo :studentInfo="studentInfo"
               @refresh="refresh"></CyberInfo>
    <div class="title">查询学员信息:</div>
    <StudentTable :studentInfo="studentInfo.info"></StudentTable>
    <div class="title">查询未提交信息:</div>
    <PreUploadTable :preUpload="studentInfo.preUploadList"
                    @refresh="refresh"></PreUploadTable>
    <div class="title">查询已提交学时信息:</div>
    <UploadedTable :uploaded="studentInfo.uploadedList"></UploadedTable>
    <div class="title">查询被退回信息:</div>
    <RejectTable :reject="studentInfo.rejectList"></RejectTable>
    <div class="title">查询提交学时赛博返回错误信息:</div>
    <CyberErrorTable :errorList="studentInfo.errorList"></CyberErrorTable>
    <div>
      <el-dialog :visible.sync="dialogVisible"
                 width="80%">
        <SubmitStudy :studentInfo="studentInfo"></SubmitStudy>
        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>
<script>

import PreUploadTable from '@/components/system/preUploadTable.vue'
import StudentTable from '@/components/system/studentTable.vue'
import UploadedTable from '@/components/system/uploadedTable.vue'
import RejectTable from '@/components/system/rejectTable.vue'
import CyberErrorTable from '@/components/system/cyberErrorTable.vue'
import CyberInfo from '@/components/system/cyberInfo.vue'


import { studentInfo } from '@/api/studentDetail'

import {
  verifyImg,
} from '@/api/student'

export default {
  components: {
    StudentTable,
    UploadedTable,
    PreUploadTable,
    RejectTable,
    CyberErrorTable,
    CyberInfo
  },
  props: {
    cardid: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 1
    }
  },
  watch: {
    cardid: {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          this.isShowSearch = false
          this.formInline.cardid = val
          this.loadData(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      formInline: {
        cardid: '',
        type: '1',
        name: ''
      },
      isShowSearch: true,
      studentInfo: {},
      code1: '',
      code4: '',
      verifyImg: '',
      subjectOneHours: '',
      subjectFourHours: '',
      dialogVisible: false
    }
  },
  methods: {
    refresh () {
      this.loadData(this.formInline.cardid)
    },
    onSubmit () {
      this.loadData(this.formInline.cardid)
    },
    async loadData (cardid) {
      this.$loading()
      const res = await studentInfo({ cardid: cardid, type: this.type })
      let data = res.data
      data.info = [res.data.info]

      data.preUploadList = data.preUploadList.filter(e => {
        return e.type == 0
      })
      if (data.studyCode) {
        if (data.studyCode.submittedCodeOne) {
          this.code1 = '科1: ' + data.studyCode.submittedCodeOne
        }
        if (data.studyCode.submittedCodeFour) {
          this.code4 = '科4: ' + data.studyCode.submittedCodeFour
        }
      }
      this.studentInfo = data
      this.subjectOneHours = data.cyberStudent.subjectOneHours
      this.subjectFourHours = data.cyberStudent.subjectFourHours

      const imgRes = await verifyImg({ "uid": data.info[0].uid })
      if (imgRes.data) {
        this.verifyImg = imgRes.data.img
      }
      this.$loading().close()
    },
    showDia () {
      if (this.studentInfo.length < 1) {
        this.$message('无数据')
        return
      }
      this.dialogVisible = true
    }
  },

}
</script>
<style lang="less" scoped>
.detail_container {
  padding: 10px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 5px;
}
.cyber-info {
  display: flex;
  .cyber-info-img {
    width: 80px;
    height: 100px;
  }
  .cyber-info-detail {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    .cyber-info-detail-text {
      font-size: 12px;
    }
  }
}
</style>