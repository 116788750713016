<template>
  <div class="list">
    <!-- 搜索栏 -->
    <div class="header-search">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">

        <el-form-item label="驾校:">
          <el-input v-model="formInline.name"
                    placeholder="请输入驾校名称"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="tableData"
                border
                style="width: 100%">
        <!-- <el-table-column prop="id"
                         label="id"
                         min-width="80"
                         align="center">
        </el-table-column> -->
        <el-table-column prop="schoolName"
                         label="驾校名称"
                         min-width="100"
                         align="center">
        </el-table-column>

        <el-table-column prop="nums"
                         label="C1/C2/C5名额"
                         min-width="80"
                         align="center">

        </el-table-column>
        <el-table-column prop="nums1"
                         label="A2/B2名额"
                         min-width="80"
                         align="center">

        </el-table-column>
        <el-table-column prop="nums2"
                         label="A2/B2从业资格名额"
                         min-width="80"
                         align="center">
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <Pagination :total="total"
                @currentChange="handleCurrentChange"></Pagination>

    <!-- 照片弹窗 -->
    <div>
      <el-dialog :visible.sync="dialogVisible"
                 width="30%"
                 :before-close="handleClose">
        <span v-if="img.length < 1">{{dialogType==1 ? '未签合同' : '暂无图片'}}</span>
        <img class="dia-img"
             :src="img">

        <span slot="footer"
              class="dialog-footer">

          <el-button v-if="img.length > 0 && dialogType ==1"
                     type="danger"
                     @click="removeContract">删 除</el-button>
          <el-button type="primary"
                     @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>

  </div>

</template>

<script>
import { list } from '@/api/school'
import Pagination from '@/components/pagination'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      formInline: {
        cardid: '',
        type: '1',
        name: ''
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      img: '',
      dialogType: 1,
      currentContractId: 0
    }
  },
  methods: {
    onSubmit () {
      this.loadData(1, this.formInline.cardid, this.formInline.type, this.formInline.name)
    },
    handleClick () {

    },
    handleCurrentChange (val) {
      this.loadData(val, "", this.formInline.type, this.formInline.name)
    },
    async loadData (page, cardid, type, name) {
      const res = await list({ page, cardid, type, name })

      this.tableData = res.data
      this.total = res.count
    },

    handleClose (done) {
      done()
    }
  },
  async created () {
    this.loadData(1, '', 1, '')
  },
}

</script>
<style lang="less" scoped>
.list {
  padding: 10px;
}
.dia-img {
  width: 100%;
}
.el-dialog__body {
  text-align: center;
}
</style>