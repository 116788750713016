import { menuList } from '@/router/menuList'
import { getRouterList } from '@/api/login'
import { rulesMenu } from '@/utils/rulesMenu'
//导入基本的路由配置layout布局容器
// import router, { baseRoutes } from "@/router/index";
import { cloneDeep } from 'lodash'

export default {
  namespaced: true,
  state: {
    dyMenuList: [],//动态导航路由
  },
  getters: {

  },
  mutations: {
    // 存储动态路由
    setMenuList (state, payload) {
      state.dyMenuList = payload
    },
    //清空动态路由
    deletMenuList (state) {
      state.dyMenuList = []
    }
  },
  actions: {
    // 定义获取路由的网络请求方法
    // getMenuList ({ commit, state, rootState }) {
    //   return new Promise(async (resolve, reject) => {
    //     let res = await getRouterList()
    //     let menus = rulesMenu(menuList, res.data)
    //     let arr = cloneDeep(baseRoutes)
    //     let routes = arr[0].children;
    //     routes.push(...menus);
    //     commit("setMenuList", routes);
    //     resolve(arr)
    //   })

    // }
  }
};
