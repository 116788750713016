<template>
  <div>
    <div class="cyber-info">
      <el-image class="cyber-info-img" :src="verifyImg"></el-image>
      <div class="cyber-info-detail">
        <span class="cyber-info-detail-text">{{ studentInfo.cyberStudent.models }}科1: {{ subjectOneHours }}</span>
        <span class="cyber-info-detail-text">{{ studentInfo.cyberStudent.models }}科4: {{ subjectFourHours }}</span>
        <span class="cyber-info-detail-text">从业科1: {{ k1 }}</span>
        <span class="cyber-info-detail-text">从业科4: {{ k4 }}</span>
        <el-button type="primary" @click="refresh">刷新</el-button>
        <el-button @click="deleteCode">删除code</el-button>
      </div>
      <div class="cyber-info-detail cyber-info-detail-border-right">
        <div class="cyber-info-detail-inputbtn">
          <el-input class="cyber-info-detail-input" v-model="studyCreateTime" placeholder=""></el-input>
          <el-button type="primary" @click="onSubmitStudyCreateTime">修改已提交时间</el-button>
        </div>
      </div>
      <div class="cyber-info-detail">
        <div class="cyber-info-detail-inputbtn">
          <el-input class="cyber-info-detail-input" v-model="preTime" placeholder=""></el-input>
          <el-button type="primary" @click="onSubmitPreTime">修改观看结束时间</el-button>
        </div>
        <div class="cyber-info-detail-inputbtn">
          <el-input class="cyber-info-detail-input" v-model="duration" placeholder=""></el-input>
          <el-button type="primary" @click="onSubmitStudyCreateTime">修改学时(秒)</el-button>
        </div>
      </div>

      <div class="cyber-info-detail">
        <UploadImg ref="verifyUpload" :buttonTitle="`上传认证照片`" @uploadSuccess="verifyUploadSuccess"></UploadImg>
        <el-button style="width: 200px;display: block-inline" type="danger"
          @click="submitDeleteVerify">删除认证照片</el-button>
      </div>
    </div>
    <div style="font-size:12px">{{ code1 }}</div>
    <div style="font-size:12px">{{ code4 }}</div>
    <div class="submit-container">
      <div class="cyber-info-detail-inputbtn">
        <span>结束观看时间</span>
        <el-input class="cyber-info-detail-input" v-model="preTime" placeholder="结束观看时间"></el-input>
        <el-button @click="settime1">2024-06-05 08:56:42</el-button>
        <el-button @click="settime2">2024-06-06 11:11:42</el-button>
      </div>
      <div class="cyber-info-detail-inputbtn">
        <span>提交时长(秒)</span>
        <el-input class="cyber-info-detail-input" v-model="duration" placeholder=""></el-input>

        <el-button @click="addFullTime">16200</el-button>
        <el-button @click="addExtraTime">11545</el-button>
      </div>

      <div class="cyber-info-detail">
        <span class="cyber-info-detail-title">上传签到、签退和过程照片</span>
        <UploadImg ref="durationUpload" :buttonTitle="`上传照片`" @uploadSuccess="durationUploadSuccess"></UploadImg>
      </div>
      <div class="cyber-info-detail cyber-info-detail-border-right">
        <el-button type="primary" :loading="uploadIsLoading" @click="submitStudy">提交学时</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import UploadImg from '@/components/system/uploadImg.vue'
import {
  updateStudyCreateTime,
  updatePerUpdateTime,
  updatePeriod,
  uploadStudyShua,
  deleteVerifyImg,
  updateVerifyImg,
  deleteCode
} from '@/api/studentDetail'
import axios from "axios"
import { sts } from '@/api/aliOss'
import { generateUDID } from '@/utils/generateUDID'
export default {
  components: {
    UploadImg
  },
  props: {
    studentInfo: Object
  },
  watch: {
    studentInfo: {
      handler: function (val, oldVal) {
        if (!val) {
          return
        }
        if (val.preUploadList && val.preUploadList.length > 0 && val.preUploadList[0].type == 0) {
          this.preTime = val.preUploadList[0].updateTime
          let item = val.preUploadList[0]
          let obj = JSON.parse(item.result)
          let imgList = []
          if (obj.file.length > 0) {
            imgList = obj.file.split(';')
          }
          obj.imgList = imgList
          if (obj) {
            val.preUploadList[0], obj = obj
            this.duration = obj.duration ?? 0
            this.img = obj.img ?? ''
            this.imgList = obj.imgList ?? []
          }
        } else {
          this.duration = 0
          this.img = ''
          this.imgList = []
          this.preTime = ''
        }
        if (val.uploadedList && val.uploadedList.length > 0) {
          this.studyCreateTime = val.uploadedList[val.uploadedList.length - 1].createTime
        } else {
          this.studyCreateTime = ''
        }
      },
      deep: true
    }
  },
  computed: {
    verifyImg () {
      if (!this.studentInfo.signImg) {
        return ''
      }
      return this.studentInfo.signImg.img
    },
    subjectOneHours () {
      var total = "12"
      if (this.studentInfo.cyberStudent.models == 'A1') {
        total = "10"
      }
      if (this.studentInfo.cyberStudent.models == 'A2') {
        total = "8"
      }
      if (this.studentInfo.cyberStudent.models == 'A3') {
        total = "14"
      }
      if (this.studentInfo.cyberStudent.models == 'B2') {
        total = "10"
      }
      if (!this.studentInfo.cyberStudent || !this.studentInfo.cyberStudent.subjectOneHours) {
        return '0/' + total + '(学时)'
      }
      return (this.studentInfo.cyberStudent.subjectOneHours * 12 / 9).toFixed(2) + '/' + total + '(学时)'
    },
    subjectFourHours () {
      var total = "10"
      if (this.studentInfo.cyberStudent.models == 'A1') {
        total = "16"
      }
      if (this.studentInfo.cyberStudent.models == 'A2') {
        total = "16"
      }
      if (this.studentInfo.cyberStudent.models == 'A3') {
        total = "16"
      }
      if (this.studentInfo.cyberStudent.models == 'B2') {
        total = "16"
      }
      if (!this.studentInfo.cyberStudent || !this.studentInfo.cyberStudent.subjectFourHours) {
        return '0/' + total + '(学时)'
      }
      return (this.studentInfo.cyberStudent.subjectFourHours * 12 / 9).toFixed(2) + '/' + total + '(学时)'
    },
    k1 () {
      if (!this.studentInfo.cyberStudent || !this.studentInfo.cyberStudent.k1) {
        return '0/4(学时)'
      }
      return (this.studentInfo.cyberStudent.k1 * 12 / 9).toFixed(2) + '/' + '4(学时)'
    },
    k4 () {
      if (!this.studentInfo.cyberStudent || !this.studentInfo.cyberStudent.k4) {
        return '0/2(学时)'
      }
      return (this.studentInfo.cyberStudent.k4 * 12 / 9).toFixed(2) + '/' + '2(学时)'
    },
    code1 () {
      if (!this.studentInfo.studyCode || !this.studentInfo.studyCode.submittedCodeOne) {
        return ''
      }
      return '科1课程码:' + this.studentInfo.studyCode.submittedCodeOne
    },
    code4 () {
      if (!this.studentInfo.studyCode || !this.studentInfo.studyCode.submittedCodeFour) {
        return ''
      }
      return '科4课程码:' + this.studentInfo.studyCode.submittedCodeFour
    }
  },
  data () {
    return {
      preTime: '',
      studyCreateTime: '',
      duration: 0,
      img: '',
      imgList: [],
      outImg: '',
      uploadIsLoading: false
    }
  },
  methods: {
    settime1 () {
      this.preTime = "2024-02-05 08:56:42"
    },
    settime2 () {
      this.preTime = "2024-02-06 11:11:42"
    },
    addFullTime () {
      this.duration = "16200"
    },
    addExtraTime () {
      this.duration = "11545"
    },
    async onSubmitPreTime () {
      let res = await updatePerUpdateTime({ userId: this.studentInfo.info[0].uid, time: this.preTime })
      if (res.data == true) {
        this.$message('修改成功')
      }
    },
    async onSubmitStudyCreateTime () {
      let res = await updateStudyCreateTime({ userId: this.studentInfo.info[0].uid, time: this.studyCreateTime })
      if (res.data == true) {
        this.$message('修改成功')
      }
    },
    durationUploadSuccess (val) {
      for (const item of val) {
        this.imgList.push(item.fileUrl)
      }
    },
    async deleteCode () {
      await deleteCode({ "cardid": this.studentInfo.cardid })
    },
    async uploadImg () {
      let res = await sts()
      console.log(res)
      let instance = axios.create()
      let data = await instance.get(this.studentInfo.signImg.img, {
        responseType: 'blob'
      })
      let blob = data.data
      console.log(blob)
      var formData = new FormData();
      let fileUrl = res.data.dir + generateUDID() + '.' + 'jpg'
      formData.append('key', fileUrl)
      // formData.append('name',file.name)
      formData.append('policy', res.data.policy)
      formData.append('OSSAccessKeyId', res.data.accessid)
      formData.append('success_action_status', '200')
      // formData.append('callback','')
      formData.append('signature', res.data.signature)
      formData.append('file', blob)
      const updata = await instance.post("https://face-than1.oss-cn-shanghai.aliyuncs.com", formData)
      console.log('------------')
      console.log(fileUrl)
      return 'https://face-than1.oss-cn-shanghai.aliyuncs.com/' + fileUrl
    },
    async submitStudy () {

      for (var i = 0; i < 11; i++) {
        let url = await this.uploadImg()
        this.imgList.push(url)
      }

      let count = Math.floor(this.duration / 1800) + 2
      let gap = count - this.imgList.length
      if (gap > 0) {
        this.$message('照片不足,缺' + gap + '张')
        return
      }
      this.uploadIsLoading = true
      let duration = this.duration
      // 签到照片
      let img = ''
      // 过程照片
      let file = ''
      // 签退照片
      let outImg = ''

      let tempList = []
      for (let index = 0; index < this.imgList.length; index++) {
        const element = this.imgList[index];
        if (index == 0) {
          img = element
        } else if (index == 1) {
          outImg = element
        } else {
          tempList.push(element)
        }
      }
      file = tempList.join(';')
      let item = {
        course: 'c1',
        deviceid: 'deviceid',
        deviceos: 'deviceos',
        duration: duration,
        file: file,
        img: img,
        name: '1',
        token: this.studentInfo.info.token,
        user_id: this.studentInfo.info.uid
      }
      let param = {}
      let result = JSON.stringify(item)
      param.result = result
      if (this.studentInfo.preUploadList.length > 0) {
        param.id = this.studentInfo.preUploadList[0].id
      }
      param.userId = this.studentInfo.info[0].uid
      param.type = 0
      param.createTime = this.preTime
      param.updateTime = this.preTime
      let res = await updatePeriod(param)
      if (!res.data) {
        this.clearData()
        this.$message('学时提交失败!!!')
        this.refresh()
        return
      }

      let ipadd = ''
      if (this.studentInfo.uploadedList.length > 0) {
        ipadd = this.studentInfo.uploadedList[0].ipadd
      }
      let resShua = await uploadStudyShua({
        uid: this.studentInfo.info[0].uid,
        file: outImg,
        ipadd: ipadd
      })
      const that = this
      setTimeout(() => {
        if (resShua.code == 0) {
          that.$message('学时提交成功')
        } else {
          that.$message('学时提交失败!!!')
        }
        that.uploadIsLoading = false
        that.clearData()
      }, 1000);
    },
    clearData () {
      this.$message('学时提交成功')
      this.preTime = ''
      this.studyCreateTime = ''
      this.duration = 0
      this.img = ''
      this.imgList = []
      this.outImg = ''
      this.$refs.durationUpload.clear()
      this.$refs.verifyUpload.clear()
      this.uploadIsLoading = false
      this.refresh()
    },
    refresh () {
      this.$emit("refresh", "")
    },
    async verifyUploadSuccess (val) {
      let path = val[0].fileUrl
      let param = {
        img: path,
        time: new Date().getTime(),
        userId: this.studentInfo.info[0].uid
      }
      if (this.studentInfo.signImg) {
        param.id = this.studentInfo.signImg.id
      }
      let res = await updateVerifyImg(param)
      if (res.data) {
        const that = this
        setTimeout(() => {
          that.clearData()
          that.$message('修改认证照片成功')
        }, 1000);
      }
    },
    async submitDeleteVerify () {
      const that = this
      this.$confirm('确认删除？')
        .then(async _ => {
          if (this.studentInfo.signImg) {
            let id = this.studentInfo.signImg.id
            let res = await deleteVerifyImg({ id: id })
            if (res.data) {
              setTimeout(() => {
                that.clearData()
                that.$message('删除认证照片成功')
              }, 1000);
            }
          }
        })
        .catch(_ => { });
    }
  }
}

</script>
<style lang="less" scoped>
.cyber-info {
  display: flex;
  align-items: center;

  .cyber-info-img {
    width: 80px;
    height: 100px;
  }

  .cyber-info-detail-border-right {
    border-right: 1px solid lightgray;
  }

  .cyber-info-detail {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    align-items: left;

    height: 100%;

    .cyber-info-detail-title {
      font-size: 12px;
    }

    .cyber-info-detail-text {
      font-size: 12px;
    }

    .cyber-info-detail-inputbtn {
      padding: 10px 0;

      .cyber-info-detail-input {
        display: inline-block;
        width: 165px;
        padding-right: 5px;
      }
    }
  }
}

.submit-container {
  margin: 20px 0 0 50px;
  width: 200px;
}
</style>
