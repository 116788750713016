<template>
  <div class="bottom">
    <el-pagination :current-page="currentPage"
                   layout="total, prev, pager, next, jumper"
                   :total="total"
                   :page-size="pageSize"
                   @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 100,
    }
  },

  data () {
    return {
      currentPage: 1
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.$emit('currentChange', val)
    }
  },
}

</script>
<style lang="less" scoped>
.bottom {
  padding-top: 20px;
  text-align: right;
}
</style>