<template>
  <div class="dia-content">
    <el-form ref="form"
             :model="form"
             label-width="100px">
      <el-form-item label="驾校名称">
        <span v-if="!isAdd">{{form.schoolName}}</span>
        <el-input v-if="isAdd"
                  v-model="form.schoolName"></el-input>
      </el-form-item>
      <el-form-item label="驾校全称">
        <el-input v-model="form.fullName"></el-input>
      </el-form-item>
      <el-form-item label="C1/C2名额">
        <el-input v-model="form.nums"></el-input>
      </el-form-item>
      <el-form-item label="A2/B2名额">
        <el-input v-model="form.nums1"></el-input>
      </el-form-item>
      <el-form-item label="A2/B2从业资格名额">
        <el-input v-model="form.nums2"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="onSubmit">保存</el-button>
        <!-- <el-button v-if="!isAdd"
                   @click="onDelete"
                   type="danger">删除驾校</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },

  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.form = val
        if (val.id) {
          this.isAdd = false
        } else {
          this.isAdd = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      form: {
        id: "",
        schoolName: "",
        nums: "",
        nums1: "",
        nums2: "",
        status: 1,
        fullName: "",
      },
      isVisible: false,
      isAdd: false
    };
  },
  methods: {
    onSubmit () {
      this.$emit("saveItem", this.form)
    },
    onDelete () {
      const that = this
      this.$confirm('确认删除驾校？该操作无法恢复,请谨慎操作!!!')
        .then(_ => {
          that.$emit("deleteItem", this.form)
        })
        .catch(_ => { });
    }
  },
};
</script>
<style lang="less" scpoed>
.dia-content {
  padding: 50px;
}
</style>
