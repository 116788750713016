<template>
  <div class="list">
    <!-- 搜索栏 -->
    <div class="header-search">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">

        <el-form-item label="身份证号码:">
          <el-input v-model="formInline.cardid"
                    placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="处理问题:">
          <el-input v-model="formInline.comment"
                    placeholder="请输入处理问题"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">添加记录</el-button>
        </el-form-item>

      </el-form>
    </div>

    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="tableData"
                border
                style="width: 100%">
        <el-table-column prop="id"
                         label="id"
                         min-width="30"
                         align="center">
        </el-table-column>
        <el-table-column prop="schoolName"
                         label="驾校名称"
                         min-width="50"
                         align="center">
        </el-table-column>

        <el-table-column prop="cardid"
                         label="身份证号码"
                         min-width="60"
                         align="center">

        </el-table-column>
        <el-table-column prop="name"
                         label="姓名"
                         min-width="40"
                         align="center">
        </el-table-column>
        <el-table-column prop="comment"
                         label="处理问题"
                         min-width="120"
                         align="center">

        </el-table-column>
        <el-table-column prop="createTime"
                         label="处理日期"
                         min-width="80"
                         align="center">

        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <Pagination :total="total"
                @currentChange="handleCurrentChange"></Pagination>

  </div>

</template>

<script>
import {
  recordList,
  recordAdd

} from '@/api/school'
import Pagination from '@/components/pagination'

export default {
  components: {
    Pagination,
  },
  data () {
    return {
      formInline: {
        cardid: '',
        comment: ''
      },
      tableData: [],
      total: 0

    }
  },
  methods: {
    async loadData (page) {
      const res = await recordList({ page })
      const list = res.data
      this.tableData = list
      this.total = res.count
    },
    async onSubmit () {
      const res = await recordAdd(this.formInline)
      this.loadData(1)
      this.formInline.cardid = ''
      this.formInline.comment = ''
    },
    handleCurrentChange (val) {
      this.loadData(val)
    },
  },

  async created () {
    this.loadData(1, '')
  },


}

</script>
<style lang="less" scoped>
.list {
  padding: 10px;
}
.dia-img {
  width: 100%;
}
.el-dialog__body {
  text-align: center;
}
</style>