<template>
  <div class="list">
    <!-- 搜索栏 -->
    <div class="header-search">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">

        <el-form-item label="驾校:">
          <el-input v-model="formInline.name"
                    placeholder="请输入驾校名称"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="addSchool">新增驾校</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="tableData"
                border
                style="width: 100%">
        <el-table-column prop="id"
                         label="id"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="schoolName"
                         label="驾校名称"
                         min-width="100"
                         align="center">
        </el-table-column>

        <el-table-column prop="nums"
                         label="C1/C2/C5名额"
                         min-width="80"
                         align="center">

        </el-table-column>
        <el-table-column prop="nums1"
                         label="A2/B2名额"
                         min-width="80"
                         align="center">

        </el-table-column>

        <el-table-column prop="nums2"
                         label="A2/B2从业资格名额"
                         min-width="80"
                         align="center">

        </el-table-column>
        <el-table-column prop="hasAccount"
                         label="是否创建账号"
                         min-width="80"
                         align="center">
          <template slot-scope="scope">
            <i v-if="scope.row.hasAccount"
               class="el-icon-check"></i>
            <i v-else
               class="el-icon-close"></i>
          </template>

        </el-table-column>

        <el-table-column label="操作"
                         width=""
                         align="center">
          <template slot-scope="scope">
            <el-button @click="handleAddNums(scope.row)"
                       type="primary"
                       size="small">增加名额</el-button>

            <el-button @click="handleOperation(scope.row)"
                       type="info"
                       size="small">修改信息</el-button>

            <el-button @click="handleAddBackendAccount(scope.row)"
                       size="small">创建后台账号</el-button>

          </template>

        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <Pagination :total="total"
                @currentChange="handleCurrentChange"></Pagination>

    <!-- 驾校详情弹窗 -->
    <el-dialog :visible.sync="dialogVisible"
               width="30%"
               :before-close="handleClose">
      <SchoolDetail :item="currentItem"
                    @saveItem="saveSchool"
                    @deleteItem="deleteSchool"></SchoolDetail>
    </el-dialog>

    <!-- 驾校新增名额弹窗 -->
    <el-dialog :visible.sync="addNumsDialogVisible"
               width="30%"
               :before-close="handleClose">
      <SchoolNumsAdd :item="currentItem"
                     @addNum="addNum"></SchoolNumsAdd>
    </el-dialog>
    <!-- 添加驾校账户 -->
    <el-dialog :visible.sync="addAccountVisible"
               width="30%"
               :before-close="handleClose">

      <el-form ref="form"
               :model="schoolAccountForm"
               label-width="100px">
        <el-form-item label="账号">
          <span>{{schoolAccountForm.username}}</span>
        </el-form-item>
        <el-form-item label="设置密码">
          <el-input v-model="schoolAccountForm.password"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码">
          <el-input v-model="schoolAccountForm.passwordAgain"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="submitSchoolAccount">保存</el-button>

        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import {
  accountList,
  save,
  update,
  deleteSchool,
  addSchoolAccount,
  addNum
} from '@/api/school'
import Pagination from '@/components/pagination'
import SchoolDetail from '@/components/system/schoolDetail.vue'
import SchoolNumsAdd from '@/components/system/schoolNumsAdd.vue'

import md5 from 'js-md5'
export default {
  components: {
    Pagination,
    SchoolDetail,
    SchoolNumsAdd
  },
  data () {
    return {
      formInline: {
        name: ''
      },
      schoolAccountForm: {
        username: '',
        password: '',
        passwordAgain: ''
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      addAccountVisible: false,
      addNumsDialogVisible: false,
      currentPage: 1,
      currentItem: {
        id: '',
        schoolName: '',
        fullName: '',
        nums: '',
        nums1: '',
        nums2: ''
      }

    }
  },
  methods: {
    onSubmit () {
      this.loadData(1, this.formInline.cardid, this.formInline.type, this.formInline.name)
    },
    handleClick () {

    },
    handleCurrentChange (val) {
      this.loadData(val, "", this.formInline.type, this.formInline.name)
      this.currentPage = val
    },
    async loadData (page, cardid, type, name) {
      const res = await accountList({ page, cardid, type, name })

      res.data.forEach(element => {
        element.hasAccount = element.username != undefined
      });
      this.tableData = res.data
      this.total = res.count
    },

    handleClose (done) {
      done()
    },
    handleOperation (item) {
      this.dialogVisible = true
      this.currentItem = item
    },
    handleAddNums (item) {
      this.addNumsDialogVisible = true
      this.currentItem = item
    },
    addSchool () {
      this.dialogVisible = true
      this.currentItem = {}
    },
    async saveSchool (item) {
      if (item.id) {
        const res = await update(item)
        if (res.data) {
          this.$message("修改驾校成功")
        }

      } else {
        const res = await save(item)
        if (res.data) {
          this.$message("新增驾校成功")
        }
      }
      this.dialogVisible = false
    },
    async deleteSchool (item) {
      if (item.id) {
        const res = await deleteSchool(item.id)
        if (res.data) {
          this.$message("删除驾校成功")
        }
      }
      this.dialogVisible = false
    },
    async addNum (item) {
      const res = await addNum(item)
      if (res.data) {
        this.$message("增加名额成功")
      }
      this.addNumsDialogVisible = false
      this.loadData(this.currentPage, this.formInline.name, this.formInline.type, this.formInline.name)
    },
    handleAddBackendAccount (item) {
      this.schoolAccountForm.username = item.schoolName
      this.addAccountVisible = true
    },
    async submitSchoolAccount () {
      if (this.schoolAccountForm.password.length < 1) {
        this.$message("请输入密码")
        return
      }
      if (this.schoolAccountForm.password !== this.schoolAccountForm.passwordAgain) {
        this.$message("两次输入的密码不一致")
        return
      }
      const res = await addSchoolAccount({
        password: md5(this.schoolAccountForm.password),
        username: this.schoolAccountForm.username
      })
      if (res.data) {
        this.addAccountVisible = false
        this.schoolAccountForm = {
          username: '',
          password: '',
          passwordAgain: ''
        }
        this.$message("更新驾校账户成功")
      }
    }
  },

  async created () {
    this.loadData(1, '', 1, '')
  },


}

</script>
<style lang="less" scoped>
.list {
  padding: 10px;
}
.dia-img {
  width: 100%;
}
.el-dialog__body {
  text-align: center;
}
</style>