<template>
  <div>
    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="preUploadList"
                border
                style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left"
                     inline
                     class="demo-table-expand">
              <el-form-item label="学习类型">
                <span>{{ props.row.obj.course }}</span>
              </el-form-item>
              <el-form-item label="是否已提交(0:未提交,1:已提交)">
                <span>{{ props.row.type }}</span>
              </el-form-item>

              <el-form-item label="签到照片">
                <imgPopButton :imgPath="imgPath"
                              @showImg="showImg(props.row.obj.img)"></imgPopButton>
              </el-form-item>
              <el-form-item label="过程照片">
                <imgPopButton v-for="(item,index) in props.row.obj.imgList"
                              :key="index"
                              :imgPath="imgPath"
                              @showImg="showImg(item)"></imgPopButton>
              </el-form-item>
              <el-form-item label="">
                <!-- <el-button type="primary"
                           @click="submitStudy">提交本条</el-button> -->
                <el-button type="danger"
                           @click="deleteStudy">删除本条</el-button>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="id"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="studyTime"
                         label="未提交时长(学时)"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="obj.duration"
                         label="未提交时长(秒)"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="createTime"
                         label="创建时间"
                         min-width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="updateTime"
                         label="最后更新时间"
                         min-width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="userId"
                         label="用户Id"
                         min-width="80"
                         align="center">
        </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>

import { deletePeriod } from "@/api/studentDetail"
export default {
  props: {
    preUpload: Array
  },
  computed: {
    preUploadList () {
      let list = this.preUpload
      if (!list) {
        return []
      }
      list.forEach(element => {
        let obj = JSON.parse(element.result)
        let imgList = []
        if (obj.file.length > 0) {
          imgList = obj.file.split(';')
        }
        obj.imgList = imgList
        element.obj = obj
        element.studyTime = element.obj.duration ? (element.obj.duration / 60 / 45).toFixed(2) : 0
      });
      return list
    }
  },
  data () {
    return {
      imgPath: ''
    }
  },
  methods: {
    showImg (imgPath) {
      this.imgPath = imgPath
    },
    submitStudy () {
      this.$emit("refresh", "")
    },
    async deleteStudy () {
      let uid = this.preUpload[0].userId
      await deletePeriod(uid)
      this.$emit("refresh", "")
    }
  }
}

</script>
<style lang="less" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 120px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-left: 10px;
  margin-right: 0;
  margin-bottom: 5px;
  width: 100%;
}
</style>