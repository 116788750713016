import axios from "axios"
import store from '@/store'

export const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000
});
// 两种拦截的区别：
// 封装axios拦截器，拦截的是请求，一般用在需要在请求中添加请求信息(例如请求头)
// 路由拦截，拦截的是页面，可以决定用户有没有权利访问某个页面

// 拦截器     技术点
instance.interceptors.request.use(config => {

  let token = store.state.login.userinfo.token
  // let token = localStorage.getItem("token");
  if (token) {
    // 携带登录凭证发起请求
    config.data["token"] = token
  }

  // config.headers["content-type"] = "application/x-www-form-urlencoded"
  // config.headers["Accept"] = "application/x-www-form-urlencoded"
  // config.headers["content-type"] = "application/json"

  return config
}, err => {

  return Promise.reject(err)
})

instance.interceptors.response.use(res => {
  // 在接收到响应之前，在执行then方法之前
  return res.data    // 返回的这个res 被then方法的res形参接收了
}, err => {
  // console.log(err)

  return Promise.reject(err)
})