import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
import Login from '@/views/Login'
import Home from '@/views/Home'

import School from '@/views/School'
import StudentList from '@/views//School/StudentManage/List'
import CoachList from '@/views/School/CoachManage/List'
import SchoolList from '@/views/School/SchoolManage/List'
import SchoolNumsAddRecord from '@/views//School/SchoolNumsAddRecord/List'

import SystemManage from '@/views/SystemManage'
import Role from '@/views/SystemManage/Role'
import Department from '@/views/SystemManage/Department'
import DataExport from '@/views/SystemManage/DataExport'
import StudentDetail from '@/views/SystemManage/StudentDetail'
import SchoolManageList from '@/views/SystemManage/SchoolDetail/List'
import CyManage from '@/views/SystemManage/CyManage'

import SchoolNumsRecord from '@/views/SystemManage/SchoolNumsRecord/List'
import ServiceRecord from '@/views/SystemManage/ServiceRecord/List'

import NotFound from '@/views/other/404'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => { })
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首页',
      isLogin: true
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          title: '首页'
        },
      },
      {
        path: '/school',
        name: 'school',
        component: School,
        meta: {
          title: '驾校信息管理'
        },
        redirect: '/school/studentList',
        children: [
          {
            path: 'studentList',
            name: 'studentList',
            component: StudentList,
            meta: {
              title: '学员信息'
            },
          },
          {
            path: 'coachList',
            name: 'coachList',
            component: CoachList,
            meta: {
              title: '教练信息'
            },
          },
          {
            path: 'schoolList',
            name: 'schoolList',
            component: SchoolList,
            meta: {
              title: '驾校信息'
            },
          }
          ,
          {
            path: 'schoolNumsAddRecord',
            name: 'schoolNumsAddRecord',
            component: SchoolNumsAddRecord,
            meta: {
              title: '驾校名额充值记录'
            },
          }

        ]
      },

      {
        path: '/systemManage',
        name: 'systemManage',
        component: SystemManage,
        meta: {
          title: '系统管理'
        },
        redirect: '/systemManage/dataExport',
        children: [
          {
            path: 'role',
            name: 'role',
            component: Role,
            meta: {
              title: '角色管理'
            },
          },
          {
            path: 'department',
            name: 'department',
            component: Department,
            meta: {
              title: '部门管理'
            },
          },
          {
            path: 'dataExport',
            name: 'dataExport',
            component: DataExport,
            meta: {
              title: '数据导出'
            },
          },
          {
            path: 'studentDetail',
            name: 'studentDetail',
            component: StudentDetail,
            meta: {
              title: '学员详情'
            },
          },
          {
            path: 'schoolManageList',
            name: 'schoolManageList',
            component: SchoolManageList,
            meta: {
              title: '驾校管理'
            },
          }
          ,
          {
            path: 'cyManage',
            name: 'cyManage',
            component: CyManage,
            meta: {
              title: 'A2B2从业管理'
            },
          },
          {
            path: 'schoolNumsRecord',
            name: 'schoolNumsRecord',
            component: SchoolNumsRecord,
            meta: {
              title: '驾校充值名额记录'
            },
          },
          {
            path: 'serviceRecord',
            name: 'serviceRecord',
            component: ServiceRecord,
            meta: {
              title: '客服记录'
            },
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
