<template>
  <div>
    <div class="export_container">
      <el-date-picker v-model="selectedMonth"
                      type="month"
                      placeholder="选择月">
      </el-date-picker>
      <ExportData :fields="json_fields"
                  :filename="filename"
                  :buttonTitle="'导出已看视频学员'"
                  :fetch="exportWatchedStudent"></ExportData>

      <ExportData :fields="json_fields"
                  :filename="filename"
                  :buttonTitle="'导出已购买教练'"
                  :fetch="exportPurchasedCoach"></ExportData>

      <ExportData :fields="json_fields"
                  :filename="filename"
                  :buttonTitle="'导出学员'"
                  :fetch="exportSelectedMonth"></ExportData>
    </div>

    <div class="export_container">
      <el-form :inline="true"
               class="demo-form-inline">
        <el-form-item label="审核版本号:">
          <el-input v-model="version"
                    placeholder="审核版本号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="updateVersion">修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  exportWatchedStudentByRange,
  exportCoachByRange,
  version,
  updateVersion
} from '@/api/export'
import {
  exportByRange
} from '@/api/student'
import {
  getFirstDay,
  getLastDay
} from '@/utils/dateUtil'
import ExportData from '@/components/exportData'
export default {
  components: {
    ExportData
  },
  data () {
    return {
      selectedMonth: '',
      studentJsonFields: {
        "用户id": "uid",
        "驾校": "schoolname",
        "姓名": "studentname",
        "身份证": "cardid",
        "手机": "phone",
        "驾照": "models",
        "开班日期": "openclassesdate",
        "创建日期": "createTime",
        "首次观看日期": "ct",
      },
      coachJsonFields: {
        "用户id": "id",
        "驾校": "schoolName",
        "姓名": "username",
        "身份证": "cardid",
        "手机": "phone",
        "价格": "price",
        "购买日期": "createTime",
      },
      json_fields: {},
      filename: '导出.xls',
      version: ""
    }
  },
  methods: {
    async exportWatchedStudent () {
      this.json_fields = this.studentJsonFields
      let date = new Date(this.selectedMonth)
      this.filename = date.getFullYear() + "年" + (date.getMonth() + 1) + '月已看视频学员.xls'
      if (this.selectedMonth.length < 1) {
        return [{}]
      }
      let res = await exportWatchedStudentByRange({ startTime: getFirstDay(this.selectedMonth), endTime: getLastDay(this.selectedMonth) })
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },

    async exportPurchasedCoach () {
      this.json_fields = this.coachJsonFields
      let date = new Date(this.selectedMonth)
      this.filename = date.getFullYear() + "年" + (date.getMonth() + 1) + '月已付款教练.xls'
      if (this.selectedMonth.length < 1) {
        return [{}]
      }
      let res = await exportCoachByRange({ startTime: getFirstDay(this.selectedMonth), endTime: getLastDay(this.selectedMonth) })
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },
    async exportSelectedMonth () {
      this.json_fields = this.studentJsonFields
      let date = new Date(this.selectedMonth)
      this.filename = date.getFullYear() + "年" + (date.getMonth() + 1) + '月学员.xls'
      if (this.selectedMonth.length < 1) {
        return [{}]
      }
      let res = await exportByRange({ startTime: getFirstDay(this.selectedMonth), endTime: getLastDay(this.selectedMonth) })
      if (res.data.length < 1) {
        return [{}]
      }
      return res.data
    },
    async updateVersion () {
      await updateVersion(this.version)
      this.loadData()
    },
    async loadData () {
      const res = await version()
      this.version = res.data.version
    }

  },
  mounted () {
    this.loadData()
  }

}

</script>
<style lang="less" scoped>
.export_container {
  padding: 10px;
  display: flex;
  align-items: center;
}
.demonstration {
  padding-right: 10px;
  width: 150px;
}
</style>