import { post } from "./base"
const OSS = require('ali-oss')

export async function sts () {
  try {
    const res = await post('aliOss/sts', {})
    return res
  } catch (error) {
    throw error
  }
}

