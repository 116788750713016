<template>
  <div>
    <el-form class="form"
             ref="form"
             label-width="100px">
      <el-form-item label="身份证号码">
        <el-input v-model="cardid"></el-input>
      </el-form-item>

      <el-form-item label="上传照片">
        <UploadImg ref="durationUpload"
                   :buttonTitle="`上传照片`"
                   @uploadSuccess="uploadSuccess"></UploadImg>
      </el-form-item>

      <el-form-item>
        <el-button :loading="isLoading"
                   type="primary"
                   @click="onSubmit">提交</el-button>
        <el-button @click="clear">清除</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImg from '@/components/system/uploadImg.vue'
import { cyBackendUpload } from '@/api/cyStudent'
export default {
  components: {
    UploadImg
  },
  data () {
    return {
      cardid: '',
      imgList: [],
      isLoading: false
    }
  },
  methods: {
    async onSubmit () {

      // console.log(this.imgList)
      // return
      if (this.imgList.length < 17) {
        this.$message("照片数不足差" + (17 - this.imgList.length) + "张")
        return
      }
      if (this.cardid.length < 4) {
        this.$message("请输入正确的身份证号码")
        return
      }
      this.isLoading = true
      try {
        const res = await cyBackendUpload({
          cardid: this.cardid,
          imgs: this.imgList.join(',')
        })
        if (res.data) {
          this.$message('提交成功')
        }
        this.isLoading = false
        this.clear()
      } catch (error) {
        this.isLoading = false
      }

    },
    clear () {
      this.cardid = ""
      this.imgList = []
      this.$refs.durationUpload.clear()
    },
    uploadSuccess (val) {
      for (const item of val) {
        this.imgList.push(item.fileUrl)
      }
    },
  }
}

</script>
<style lang="less" scoped>
.form {
  padding: 20px;
  width: 500px;
}
</style>