import { post } from "./base"

export async function list (params) {
  try {
    const res = await post('becoach/list', params)
    return res
  } catch (error) {
    throw error
  }
}


