export default {
  namespaced: true,
  state: {
    userinfo: {
      username: "",
      token: "",
    },
  },
  getters: {

  },
  mutations: {
    setUser (state, payload) {
      state.userinfo = payload;
    },
    deleteUser (state) {
      console.log(state)
      state.userinfo = {
        username: "",
        token: "",
      };
    },
  },
};
