<template>

  <download-excel style="display: inline-block !important;padding: 0 0 0 10px;"
                  :fetch="fetch"
                  :fields="fields"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                  :name="filename"
                  type="csv">

    <el-button :loading="isLoading"
               type="warning">{{buttonTitle}}</el-button>
  </download-excel>

</template>

<script>
export default {
  props: {
    fields: {
      type: Object,
      default: {}
    },
    filename: {
      type: String,
      default: '导出数据.xls',
    },
    buttonTitle: {
      type: String,
      default: '导出excel',
    },
    fetch: {
      type: Function
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    startDownload () {
      this.isLoading = true
    },
    finishDownload () {
      this.isLoading = false
    }
  }
}

</script>
<style lang="less" scoped>
.excel {
  display: inline-block;
  padding: 10px;
}
</style>