<template>
  <div>bumen </div>
</template>

<script>
export default {
}

</script>
<style>
</style>