<template>
  <div style=" padding:30px ;display:flex;align-items:center">
    <img src="../../assets/images/logo.png"
         style="width:50px;height:50px">
    <h2 style="padding-left:20px">纵横二加一网络信息科技有限公司</h2>
  </div>
</template>

<script>

export default {

  async created () {


  }




}
</script>

<style lang="less">
</style>