import { post } from "./base"

export async function studentWatchedList (params) {
  try {
    const res = await post('studentVideoWatcheds/exportStudent', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function exportWatchedCurrentMonth () {
  try {
    const res = await post('studentVideoWatcheds/exportCurrentMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportWatchedLastMonth () {
  try {
    const res = await post('studentVideoWatcheds/exportLastMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportPurchasedCoachCurrentMonth () {
  try {
    const res = await post('becoach/exportCurrentMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportPurchasedCoachLastMonth () {
  try {
    const res = await post('becoach/exportLastMonth', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function exportCoachByRange (params) {
  try {
    const res = await post('becoach/exportCoachByRange', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function exportWatchedStudentByRange (params) {
  try {
    const res = await post('bestudent/exportWatchedStudentByRange', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function version () {
  try {
    const res = await post('osVersionalls/version', {})
    return res
  } catch (error) {
    throw error
  }
}

export async function updateVersion (version) {
  try {
    const res = await post('osVersionalls/updateVersion', { version })
    return res
  } catch (error) {
    throw error
  }
}
