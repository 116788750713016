import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)
import login from './login'
import menu from './menu'


export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    menu
  },
  plugins: [
    createPersistedstate({
      key: 'jkStore-client',
      paths: ['login', 'menu'],
      storage: window.localStorage
    })
  ]
})
