<template>
  <div class="list">
    <!-- 搜索栏 -->
    <div class="header-search">
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">

        <el-form-item label="驾校:">
          <el-input v-model="formInline.name"
                    placeholder="请输入驾校名称"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">查询</el-button>
        </el-form-item>

      </el-form>
    </div>

    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="tableData"
                border
                style="width: 100%">
        <el-table-column prop="id"
                         label="id"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="schoolName"
                         label="驾校名称"
                         min-width="100"
                         align="center">
        </el-table-column>

        <el-table-column prop="nums"
                         label="充值名额数量"
                         min-width="80"
                         align="center">

        </el-table-column>
        <el-table-column prop="typeName"
                         label="充值名额类型"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="createTime"
                         label="充值日期"
                         min-width="80"
                         align="center">

        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <Pagination :total="total"
                @currentChange="handleCurrentChange"></Pagination>

  </div>

</template>

<script>
import {
  numsRecordList,

} from '@/api/school'
import Pagination from '@/components/pagination'
import SchoolDetail from '@/components/system/schoolDetail.vue'
import md5 from 'js-md5'
export default {
  components: {
    Pagination,
    SchoolDetail
  },
  data () {
    return {
      formInline: {
        name: ''
      },
      tableData: [],
      total: 0

    }
  },
  methods: {


    async loadData (page, name) {
      const res = await numsRecordList({ page, name })
      const list = res.data
      list.forEach(element => {
        if (element.type == 1) {
          element.typeName = "小车名额"
        } else if (element.type == 2) {
          element.typeName = "大车名额"
        } else {
          element.typeName = "从业资格证名额"
        }
      });
      this.tableData = list
      this.total = res.count
    },
    onSubmit () {
      this.loadData(1, this.formInline.name)
    },
    handleCurrentChange (val) {
      this.loadData(val, this.formInline.name)
    },
  },

  async created () {
    this.loadData(1, '')
  },


}

</script>
<style lang="less" scoped>
.list {
  padding: 10px;
}
.dia-img {
  width: 100%;
}
.el-dialog__body {
  text-align: center;
}
</style>