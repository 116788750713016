<template>
  <div>
    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="uploadedList"
                border
                style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left"
                     inline
                     class="demo-table-expand">
              <el-form-item label="学员编号">
                <span>{{ props.row.stunum }}</span>
              </el-form-item>
              <el-form-item label="培训机构编号">
                <span>{{ props.row.inscode }}</span>
              </el-form-item>
              <el-form-item label="ip地址">
                <span>{{ props.row.ipadd }}</span>
              </el-form-item>
              <el-form-item label="用户id">
                <span>{{ props.row.userId }}</span>
              </el-form-item>
              <el-form-item label="姓名">
                <span>{{ props.row.name }}</span>
              </el-form-item>
              <el-form-item label="身份证号码">
                <span>{{ props.row.idcardnum }}</span>
              </el-form-item>
              <el-form-item label="课程码">
                <span>{{ props.row.course }}</span>
              </el-form-item>
              <el-form-item label="认证照片">
                <imgPopButton :imgPath="imgPath"
                              @showImg="showImg(props.row.photo)"></imgPopButton>
              </el-form-item>
              <el-form-item label="签到照片">
                <imgPopButton :imgPath="imgPath"
                              @showImg="showImg(props.row.signPhoto)"></imgPopButton>
              </el-form-item>
              <el-form-item label="签退照片">
                <imgPopButton :imgPath="imgPath"
                              @showImg="showImg(props.row.upPhoto)"></imgPopButton>
              </el-form-item>
              <el-form-item label="过程照片">
                <imgPopButton v-for="(item,index) in props.row.imgList"
                              :key="index"
                              :imgPath="imgPath"
                              @showImg="showImg(item)"></imgPopButton>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="id"
                         width="105"
                         align="center">
        </el-table-column>
        <el-table-column prop="recnum"
                         label="学习编号"
                         width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="duration"
                         label="提交学时(秒)"
                         width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="createTime"
                         label="提交时间"
                         min-width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="starttime"
                         label="开始时间"
                         min-width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="endtime"
                         label="结束时间"
                         min-width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="message"
                         label="提交返回信息"
                         width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="reason"
                         label="reason"
                         width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="subject"
                         label="科目"
                         width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="traintype"
                         label="培训类型"
                         width="120"
                         align="center">
        </el-table-column>

      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uploaded: Array
  },
  computed: {
    uploadedList () {
      let list = this.uploaded
      if (!list) {
        return []
      }
      list.forEach(element => {
        element.imgList = []
        if (element.photo2.length > 0) {
          element.imgList = element.photo2.split(";")
        }

      });
      return list
    }
  },
  data () {
    return {
      imgPath: '',
    }
  },
  methods: {
    showImg (imgPath) {
      this.imgPath = imgPath
    }
  }
}

</script>
<style lang="less" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 120px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-left: 10px;
  margin-right: 0;
  margin-bottom: 5px;
  width: 100%;
}
</style>