export function getFirstDay (dateStr) {
  let date = new Date(dateStr)
  var y = date.getFullYear(); //获取年份
  var m = date.getMonth() + 1; //获取月份
  var d = '01'
  m = m < 10 ? '0' + m : m; //月份补 0
  return [y, m, d].join("-") + " 00:00:00"
}

export function getLastDay (dateStr) {
  let date = new Date(dateStr)
  var y = date.getFullYear(); //获取年份
  var m = date.getMonth() + 1; //获取月份
  var d = new Date(y, m, 0).getDate(); //获取当月最后一日
  m = m < 10 ? '0' + m : m; //月份补 0
  d = d < 10 ? '0' + d : d; //日数补 0
  return [y, m, d].join("-") + " 23:59:59"
}