<template>
  <div>
    <router-link to="/">返回首页</router-link>
  </div>
</template>

<script>
export default {
}

</script>
<style>
</style>