import { post } from "./base"

export async function cyBackendUpload (params) {
  try {
    const res = await post('studentCy/cyBackendUpload', params)
    return res
  } catch (error) {
    throw error
  }
}








