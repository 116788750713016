import { post } from "./base"

export async function list (params) {
  try {
    const res = await post('beschool/list', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function update (params) {
  try {
    const res = await post('beschool/update', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function save (params) {
  try {
    const res = await post('beschool/save', params)
    return res
  } catch (error) {
    throw error
  }
}
export async function deleteSchool (id) {
  try {
    const res = await post('beschool/deleteSchool', { id })
    return res
  } catch (error) {
    throw error
  }
}

export async function addSchoolAccount (params) {
  try {
    const res = await post('beschool/addSchoolAccount', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function accountList (params) {
  try {
    const res = await post('beschool/accountList', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function changePassword (params) {
  try {
    const res = await post('beschool/changePassword', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function numsRecordList (params) {
  try {
    const res = await post('schoolNumsRecords/list', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function addNum (params) {
  try {
    const res = await post('schoolNumsRecords/addNum', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function recordList (params) {
  try {
    const res = await post('serviceRecords/list', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function recordAdd (params) {
  try {
    const res = await post('serviceRecords/recordAdd', params)
    return res
  } catch (error) {
    throw error
  }
}