import { post } from "./base";

export async function studentInfo(params) {
  try {
    const res = await post("beStudentDetail/infoAll", params);
    return res;
  } catch (error) {
    throw error;
  }
}
export async function deleteCode(params) {
  try {
    const res = await post("beStudentDetail/deleteCode", params);
    return res;
  } catch (error) {
    throw error;
  }
}
// export async function refreshStudentInfo (params) {
//   try {
//     const res = await post('beStudentDetail/refreshInfo', params)
//     return res
//   } catch (error) {
//     throw error
//   }
// }

export async function updateStudyCreateTime(params) {
  try {
    const res = await post("beStudentDetail/updateStudyCreateTime", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updatePerUpdateTime(params) {
  try {
    const res = await post("beStudentDetail/updatePerUpdateTime", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updatePeriod(params) {
  try {
    const res = await post("beStudentDetail/updatePeriod", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function uploadStudyShua(params) {
  try {
    const res = await post("student/beshua", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteVerifyImg(params) {
  try {
    const res = await post("bestudent/deleteVerifyImg", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function updateVerifyImg(params) {
  try {
    const res = await post("bestudent/updateVerifyImg", params);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deletePeriod(uid) {
  try {
    const res = await post("beStudentDetail/deletePeriod", { uid });
    return res;
  } catch (error) {
    throw error;
  }
}
