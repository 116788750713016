<template>
  <div class="layout">
    <!-- 左侧导航 -->
    <div class="menu">
      <Menu :isCollapse="isCollapse"></Menu>
    </div>

    <!-- 右侧内容 -->
    <div class="content" :class="{active:isCollapse}">
      <Content @changeManu="changeManu" :isCollapse="isCollapse"></Content>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import Content from "./Content";
export default {
  data () {
    return {
      isCollapse: false
    }
  },
  components: {
    Menu,
    Content,
  },
  methods: {
    changeManu(){
      this.isCollapse = !this.isCollapse
    }
  },
};
</script>

<style lang="less" scoped>
.layout {
  .menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    // width: 200px;
    background: #112f50;
    /deep/ .el-menu {
      border-right: none;
    }
  }
  .content {
    padding-left: 200px;
    transition: all .5s;
  }
  .active{
    padding-left: 64px;
  }
}
</style>
