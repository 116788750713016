<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app',
  components: {}
}
</script>

<style>
  .demo {
    color: #000;
  }
</style>
