<template>
  <div style="display:inline-block">
    <el-popover placement="right"
                width="100"
                trigger="click">
      <el-image style="width: 100%; height: 100%"
                :src="imgPath"></el-image>
      <el-button slot="reference"
                 @click="showImg()">图片</el-button>
    </el-popover>

  </div>
</template>

<script>
export default {
  name: 'imgPopButton',
  props: {
    imgPath: String
  },
  methods: {
    showImg () {
      this.$emit('showImg', '')
    }

  }
}

</script>
<style>
</style>