<template>
  <div style="padding-bottom:5px">
    <el-upload ref="upload"
               action="https://face-than1.oss-cn-shanghai.aliyuncs.com"
               :data="dataObj"
               list-type="text"
               :multiple="true"
               :show-file-list="true"
               :file-list="fileList"
               :before-upload="beforeUpload"
               :on-remove="handleRemove"
               :on-success="handleUploadSuccess"
               :on-preview="handlePreview"
               :auto-upload="false">
      <el-button slot="trigger"
                 size="small"
                 type="primary">选取文件</el-button>
      <el-button style="margin-left: 10px;"
                 size="small"
                 type="success"
                 @click="submitUpload">{{buttonTitle}}</el-button>
    </el-upload>
  </div>
</template>
<script>
import { sts } from '@/api/aliOss'
import { generateUDID } from '@/utils/generateUDID'
import * as imageConversion from 'image-conversion'
export default {
  props: {
    buttonTitle: {
      type: String,
      default: '点击上传'
    }
  },
  data () {
    return {
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      fileList: [],
      filePaths: [],
      isEmitted: false
    }
  },
  computed: {

  },
  methods: {
    submitUpload () {
      this.isEmitted = false
      this.$refs.upload.submit();
    },
    handleRemove (file, fileList) {
      this.filePaths.pop(file)
      fileList.pop(file)
    },
    handlePreview (file) {

    },
    async beforeUpload (file) {
      return new Promise((resolve) => {
        // 压缩到100KB,这里的100就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 100).then(async (f) => {
          let res = await sts()
          let data = res.data
          this.dataObj.policy = data.policy
          this.dataObj.signature = data.signature
          this.dataObj.ossaccessKeyId = data.accessid
          this.dataObj.key = data.dir + generateUDID() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1)
          this.dataObj.dir = data.dir
          this.dataObj.host = data.host
          f.fileUrl = data.host + '/' + this.dataObj.key
          this.filePaths.push(f)
          resolve(f);
        });
      })
    },
    handleUploadSuccess (response, file, fileList) {
      if (this.isEmitted) {
        return
      }
      let allSuccess = true
      fileList.forEach(element => {
        if (element.status !== 'success') {
          allSuccess = false
        }
      });
      if (allSuccess) {
        this.$emit('uploadSuccess', this.filePaths)
        this.isEmitted = true
      }
    },
    clear () {
      this.$refs.upload.clearFiles()
      this.fileList = []
      this.filePaths = []
    }
  }
}
</script>