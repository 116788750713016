<template>
  <div>
    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="reject"
                border
                style="width: 100%">
        <el-table-column prop="id"
                         label="id"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="recnum"
                         label="学习编号"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="duration"
                         label="提交时长(分钟)"
                         min-width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="deductionduration"
                         label="扣减时长(分钟)"
                         min-width="120"
                         align="center">
        </el-table-column>
        <el-table-column prop="deductionreson"
                         label="扣减原因"
                         min-width="200"
                         align="center">
        </el-table-column>
        <el-table-column prop="idcardnum"
                         label="身份证号码"
                         min-width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="crateTime"
                         label="创建时间"
                         min-width="100"
                         align="center">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    reject: Array
  }
}

</script>
<style>
</style>