<template>
  <div id="login_wrap">
    <div id="login">
      <div style=" padding-bottom:50px ;display:flex;align-items:center; justify-content: center;">
        <img src="../../assets/images/logo.png"
             style="width:50px;height:50px">
        <h2 style="padding-left:20px">纵横二加一</h2>
      </div>
      <div class="login--account bottom-line">
        <span>账号：</span>
        <input type="text"
               placeholder="请输入账号"
               name="account"
               v-model.trim="username" />
      </div>
      <div class="login--password bottom-line">
        <span>密码：</span>
        <input type="password"
               placeholder="请输入密码"
               name="password"
               v-model.trim="password" />
      </div>
      <p class="login--btn">
        <el-button id="loginBtn"
                   type="primary"
                   @click="getLogin">{{"登   录"}}</el-button>
      </p>

    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { login } from '@/api/login'
import md5 from 'js-md5';
export default {
  data () {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations('login', ['setUser']),
    //点击登录--------------------
    getLogin () {
      this.login(this.username, this.password);
    },
    //登录接口----------------------------------------------
    async login (username, password) {
      let pwd = md5(password)
      try {
        let res = await login({ username, password: pwd })
        let obj = {
          username,
          token: res.data.token
        }
        //设置store仓库
        this.setUser(obj)
        //跳转网页
        this.$router.push('/')
      } catch (error) {
        console.log(error)
        this.$message(error.response.data.message)
      }
    }
  }
}
</script>

<style scoped lang="less">
.title {
  text-align: center;
  font-size: 22px;
}
#login_wrap {
  // position: relative;
  //  justify-content: center;
  background: rgba(64, 64, 194, 0.1);
  height: 100%;
  > div {
    background: #fff;
    width: 479px;
    height: 325px;
    padding: 30px 40px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    .bottom-line {
      border-bottom: 1px solid #ddd;
    }
    > div {
      padding: 10px 0;

      &.login--account {
        margin: 25px 0 30px;
      }
      span {
        color: #666;
        display: inline-block;
        width: 84px;
        font-size: 20px;
      }
      input {
        background: none;
        font-size: 16px;
        border: none;
        height: 30px;
        width: 280px;
        padding-left: 12px;
        box-sizing: border-box;
        color: #666;
        &.error {
          border: 1px solid #f00;
        }
        &::-webkit-input-placeholder {
          color: #aaa;
        }
      }
    }

    p {
      text-align: right;
      &.login--btn {
        button {
          width: 100%;
          height: 50px;
          font-size: 18px;
          // border: none;
          margin-top: 30px;
          // color: #fff;
          // border-radius: 6px;
          // cursor: pointer;
        }
      }
      a {
        color: #fff;
        display: inline-block;
        padding: 0 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
