<template>
  <div>
    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="studentInfo" border style="width: 100%">
        <el-table-column prop="uid" label="id" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="studentname" label="姓名" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="cardid" label="身份证号码" min-width="200" align="center">
        </el-table-column>
        <el-table-column prop="models" label="驾照类型" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="subjectonehours" label="科一学时" min-width="70" align="center">
        </el-table-column>
        <el-table-column prop="subjectfourhours" label="科四学时" min-width="70" align="center">
        </el-table-column>
        <el-table-column prop="k1" label="从业科一" min-width="70" align="center">
        </el-table-column>
        <el-table-column prop="k4" label="从业科四" min-width="70" align="center">
        </el-table-column>
        <el-table-column prop="openclassesdate" label="开训时间" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="sckxrq" label="实操开训时间" min-width="80" align="center">
        </el-table-column>

        <el-table-column prop="schoolname" label="驾校名称" min-width="120" align="center">
        </el-table-column>

        <el-table-column prop="phone" label="手机号" min-width="120" align="center">
        </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    studentInfo: Array
  }
}

</script>
<style></style>
