import { instance } from './request'
import { Message, Loading } from "element-ui";
import router from "@/router";

export async function post (url, parameters) {
  const res = await request(
    'POST',
    url,
    parameters
  )
  return res
}

export async function get (url, parameters) {
  const res = await request(
    'GET',
    url,
    parameters
  )
  return res
}


export async function request (method, url, parameters) {
  let config = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  };
  let loadingInstance = Loading.service();
  return new Promise((resolve, reject) => {
    if (method === 'GET') {
      instance.get(url, parameters, config).then(res => {
        loadingInstance.close()
        if (res.code !== 0) {
          Message.error(res.message ?? '出错啦')
          resolve(res)
          return
        }
        resolve(res)
      }).catch(error => {
        loadingInstance.close()
        if (error.response.status !== 200) {
          if (error.response.data) {
            if (error.response.data.code && error.response.data.code == 60000) {
              router.push('/login')
              Message.error(error.response.data.message)
              return
            }
          }
          Message.error("请重试")
        }
      })
    } else if (method === 'POST') {
      instance.post(url, parameters, config).then(res => {
        loadingInstance.close()
        if (res.code !== 0) {

          Message.error(res.message ?? '出错啦')
          resolve(res)
          return
        }
        resolve(res)
      }).catch(error => {
        loadingInstance.close()
        if (error.response.status !== 200) {
          if (error.response.data) {
            if (error.response.data.code && error.response.data.code == 60000) {
              router.push('/login')
              Message.error(error.response.data.message)
              return
            }
          }
          Message.error("请重试")
        }
      })
    }
  })
}

function handleSuccess (res, resolve) {
  if (res.code !== 0) {
    let loadingInstance = Loading.service();
    loadingInstance.close()
    Message.error(res.message ?? '出错啦')
    resolve(res)
    return
  }
  resolve(res)
}

function handleFailure (error, resolve) {
  let loadingInstance = Loading.service();
  loadingInstance.close()
  if (error.response.status !== 200) {
    if (error.response.data) {
      if (error.response.data.code && error.response.data.code == 60000) {
        router.push('/login')
        Message.error(error.response.data.message)
        return
      }
    }
    Message.error("请重试")
  }
}