export function generateUDID () {
  const numbers = []
  const size = 4

  let udid = ''
  let summary = 0

  for (let i = 0; i < size; i++) {
    const number = Math.floor((1 + Math.random()) * 0x100000000)
    summary += (number - 0x100000000)
    numbers.push(number)
  }

  numbers.push(summary + 0x100000000)
  for (let i = 0; i < numbers.length; i++) {
    udid += numbers[i].toString(16).substring(1)
  }

  return udid
}
