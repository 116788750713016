import { post } from "./base"

export async function login (params) {
  try {
    const res = await post('sysUsers/login', params)
    return res
  } catch (error) {
    throw error
  }
}

export async function getRouterList () {
  try {
    const res = await post('berouter/router', {})
    return res
  } catch (error) {
    throw error
  }
}
