<template>
  <div>
    <!-- 顶部区域 -->
    <div class="header">
      <div class="header-left">
        <el-breadcrumb separator-class="el-icon-arrow-right">

          <el-breadcrumb-item v-for="(item,index) in navData"
                              :key="index"><span class="breadcrumb-title">{{item.meta.title}}</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="header-right">
        <span>欢迎:{{userinfo.username}}</span>
        <span class="change-password"
              @click="changePassword">修改密码</span>
        <span class="exit"
              @click="logout">
          <i class="el-icon-switch-button"></i>
          退出登录
        </span>
      </div>
    </div>
    <el-dialog title="修改密码"
               :visible.sync="dialogVisible"
               width="30%"
               :before-close="handleClose">

      <el-form ref="form"
               :model="passwordForm"
               label-width="100px">
        <el-form-item label="原密码">
          <el-input v-model="passwordForm.orginalPassword"></el-input>
        </el-form-item>
        <el-form-item label="设置密码">
          <el-input v-model="passwordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码">
          <el-input v-model="passwordForm.passwordAgain"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="submitPassword">保存</el-button>

        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 内容区域 -->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { changePassword } from '@/api/school'
import md5 from 'js-md5';
export default {

  props: ['isCollapse'],
  data () {
    return {
      nowTime: '',
      dialogVisible: false,
      passwordForm: {
        orginalPassword: '',
        password: '',
        passwordAgain: ''
      }
    }
  },
  methods: {
    ...mapMutations('login', ['deleteUser']),
    changeMenu () {
      this.$emit('changeManu')
    },
    logout () {
      const that = this
      this.$confirm('确认退出登录？')
        .then(async _ => {

          this.deleteUser()
          //跳转网页
          this.$router.push('/')

        })
        .catch(_ => { });
    },
    changePassword () {
      this.dialogVisible = true
    },
    handleClose (done) {
      done()
    },
    async submitPassword () {
      if (this.passwordForm.password.length < 1) {
        this.$message("请输入密码")
        return
      }
      if (this.passwordForm.password != this.passwordForm.passwordAgain) {
        this.$message("两次密码输入不一致")
        return
      }

      const res = await changePassword({ orginalPassword: md5(this.passwordForm.orginalPassword), password: md5(this.passwordForm.password) })
      if (res.data) {
        this.$message("修改密码成功")
        this.dialogVisible = false
      }
    }
  },
  created () {

  },
  computed: {
    ...mapState('login', ['userinfo']),
    navData () {
      let matched = this.$route.matched
      matched[0].path = '/'
      return matched
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  background: #1e78bf;
  height: 50px;
  line-height: 50px;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;

  .icon {
    font-size: 24px;
  }
  .header-left {
    text-align: left;
    padding-left: 10px;
    .breadcrumb-title {
      color: #fff;
      font-size: 12px;
    }
  }
  .header-right {
    flex: 1;
    text-align: right;
    padding-right: 20px;
    font-size: 12px;
    .change-password {
      margin-left: 20px;
    }
    .line {
      display: inline-block;
      font-size: 12px;
      padding: 10px;
      color: #f5f5f5;
    }

    .exit {
      font-size: 12px;
      padding-left: 20px;
    }
  }
}
</style>