<template>
  <div>
    <!-- 表格栏 -->
    <div class="list-table">
      <el-table :data="errorList"
                border
                style="width: 100%">
        <el-table-column prop="id"
                         label="id"
                         min-width="50"
                         align="center">
        </el-table-column>
        <el-table-column prop="errorCode"
                         label="errorCode"
                         min-width="50"
                         align="center">
        </el-table-column>
        <el-table-column prop="message"
                         label="错误信息"
                         min-width="200"
                         align="center">
        </el-table-column>
        <el-table-column prop="userId"
                         label="用户Id"
                         min-width="80"
                         align="center">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    errorList: Array
  }
}

</script>
<style>
</style>