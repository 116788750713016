<template>
  <SysStudentDetail>
  </SysStudentDetail>
</template>
<script>

import SysStudentDetail from '@/components/system/sysStudentDetail.vue'

export default {
  components: {
    SysStudentDetail
  },
}
</script>
<style lang="less" scoped>
</style>